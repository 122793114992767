document.addEventListener('DOMContentLoaded', function(){

  var trigger = new ScrollTrigger({
    toggle: {
      visible: 'is-visible',
      hidden: 'is-not-visible'
    },
    offset: {
      x: 0,
      y: 100
    },
    once: true
  }, document.body, window);

});
